import React, { useCallback, useState } from 'react';
import { BasicHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { Typography } from '@mui/material';
import {
  HEADERS,
  HEADER_TITLE,
  COMPANY_COLUMNS,
} from '../../constants/company-recommendations';
import CompanyInvoiceRecommendations from './components/CompanyInvoiceRecommendations';
import CompanyRecommendationsActions from './actions/CompanyRecommendationsActions';
import { RECOMMENDED_MASTER_ENTITIES } from '../../graphql/company-recommendations/queries';

const CompanyRecommendations = () => {
  const country = useGetCountryFromUrl();
  const [selectedExecutive, setSelectedExecutive] = useState();
  const onExecutiveChange = useCallback(
    (executiveId) => setSelectedExecutive(executiveId),
    [],
  );

  return (
    <FingoMainView
      id="company-recommendations"
      query={RECOMMENDED_MASTER_ENTITIES}
      queryCustomVariables={{
        withInvoiceRecommendation: true,
        executiveId: selectedExecutive,
        countryId: country?.id,
      }}
      slots={{
        header: BasicHeader,
        actions: CompanyRecommendationsActions,
        table: FingoTable,
      }}
      slotProps={{
        header: {
          title: HEADER_TITLE,
        },
        actions: {
          onExecutiveChange,
        },
        table: {
          columns: COMPANY_COLUMNS,
          includeHeaders: HEADERS,
          checkboxSelection: false,
          collapsible: true,
          isRowCollapsible: ({ row }) => !!row,
          collapseComponent: CompanyInvoiceRecommendations,
          noRowsMessage: () => (
            <Typography>Sin clientes con recomendación</Typography>
          ),
          rowsPerPageOptions: [10, 25, 50, 100],
        },
      }}
    />
  );
};

export default CompanyRecommendations;
