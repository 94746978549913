import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { MAIL_TEMPLATE_PREVIEW } from '@fingo/lib/graphql/notification-center/queries';
import { useQuery } from '@apollo/client';

const MailTemplatePreview = ({ template }) => {
  const { data, loading, error } = useQuery(MAIL_TEMPLATE_PREVIEW, {
    variables: { sendgridId: template },
  });

  let content;

  if (loading) {
    content = <CircularProgress />;
  } else if (error) {
    content = <p>Error al cargar la vista previa del template</p>;
  } else if (data && data.mailTemplatePreview) {
    content = parse(data.mailTemplatePreview);
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
      <Box sx={{ transform: 'scale(0.8)', transformOrigin: 'top center' }}>
        {content}
      </Box>
    </Box>
  );
};

MailTemplatePreview.propTypes = {
  template: PropTypes.string.isRequired,
};

export default MailTemplatePreview;
