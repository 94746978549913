import React, { useEffect } from 'react';
import { Stack, IconButton } from '@mui/material';
import MailParametersFilter from '@fingo/lib/components/filters/MailParametersFilter';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { MailParamType } from '@fingo/lib/propTypes';

const MailParameter = ({
  mailParams,
  selectedMailParam,
  setSelectedMailParam,
  openCreateMailParamForm,
  setOpenCreateMailParamForm,
  specificMode,
}) => {
  useEffect(() => {
    if (!selectedMailParam) {
      setOpenCreateMailParamForm(false);
    }
  }, [selectedMailParam]);

  return (
    <Stack spacing={3}>
      <Stack direction="row">
        <MailParametersFilter
          size="small"
          sx={{ width: '300px' }}
          selectedMailParam={selectedMailParam}
          setSelectedMailParam={setSelectedMailParam}
          mailParams={mailParams}
          specificMode={specificMode}
        />
        { !openCreateMailParamForm && !selectedMailParam && (
          <IconButton
            id="add-mail-parameters"
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateMailParamForm(true)}
          >
            <Add fontSize="12px" />
          </IconButton>
        )}
        { openCreateMailParamForm && (
          <IconButton
            id="close-mail-parameters"
            variant="contained"
            color="gray"
            onClick={() => setOpenCreateMailParamForm(false)}
          >
            <Close fontSize="12px" />
          </IconButton>
        )}
        { selectedMailParam && !openCreateMailParamForm && !specificMode && (
          <IconButton
            id="edit-mail-parameters"
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateMailParamForm(true)}
          >
            <Edit fontSize="12px" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

MailParameter.propTypes = {
  mailParams: PropTypes.arrayOf(MailParamType),
  selectedMailParam: MailParamType,
  setSelectedMailParam: PropTypes.func.isRequired,
  openCreateMailParamForm: PropTypes.bool.isRequired,
  setOpenCreateMailParamForm: PropTypes.func.isRequired,
  specificMode: PropTypes.bool.isRequired,
};

MailParameter.defaultProps = {
  mailParams: [],
  selectedMailParam: null,
};

export default MailParameter;
