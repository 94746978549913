/* eslint-disable react/prop-types */
import React from 'react';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { Typography } from '@mui/material';
import { COLUMNS_DETAIL, HEADERS_DETAIL } from '../../constants/contactability-company';

const CompanyControlGroups = ({ company, masterEntityName }) => {
  const rowsWithCompanyInfo = company.controlGroupDetail.map((detail) => ({
    ...detail,
    companyId: company.id,
    masterEntityName,
  }));

  return (
    <FingoTable
      rows={rowsWithCompanyInfo}
      columns={COLUMNS_DETAIL}
      includeHeaders={HEADERS_DETAIL}
      noRowsMessage={
          () => <Typography>La empresa no pertence a ningún grupo de control</Typography>
        }
      customPaperBackground="transparent"
      pagination={false}
      loading={false}
    />
  );
};

export default CompanyControlGroups;
