import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { COMMERCIAL_MASTER_ENTITIES } from '@fingo/lib/graphql';
import { useGetUser } from '@fingo/lib/hooks';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import Lara from './components/Lara';
import CustomersActions from './actions/CustomersActions';

const Customers = () => {
  const user = useGetUser();
  const perm = 'invoices.lara';
  const newColumns = [];
  const [executiveAssigned, setExecutiveAssigned] = useState('');
  const [farmingHunting, setFarmingHunting] = useState('');

  if (user.permissions.includes(perm)) {
    newColumns.push({
      field: 'lara',
      type: 'string',
      headerName: 'Lara',
      renderCell: ({ row }) => (
        <Lara masterEntityId={row.id} />
      ),
      sortable: false,
      flex: 0.05,
    });
  }
  const country = useGetCountryFromUrl();

  return (
    <FingoMainView
      id="customers-manager"
      query={COMMERCIAL_MASTER_ENTITIES}
      queryCustomVariables={{
        company_Isnull: false,
        countryId: country?.id,
        companyExecutiveAssignedId: executiveAssigned?.graphqlId,
        farmingByCommercialType: farmingHunting,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
        actions: CustomersActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Lista de clientes',
          finder: {
            searchPlaceHolder: 'Buscar cliente',
          },
        },
        table: {
          columns: useMasterEntityPreColumns(newColumns, true),
          noRowsMessage: () => <Typography>No tienes clientes asociados</Typography>,
          includeHeaders: [
            'name',
            'documents',
            'hasCredentials',
            'creditLine_CreditUsed',
            'company_ExecutiveAssigned__firstName',
            'lara',
          ],
          rowsPerPageOptions: [15, 25, 50, 100],
        },
        actions: {
          setExecutiveAssigned,
          setFarmingHunting,
        },
      }}
    />
  );
};

export default Customers;
