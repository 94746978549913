import { useMutation } from '@apollo/client';
import { UploadDropZoneDialog } from '@fingo/lib/components/dialogs';
import { UPLOAD_CONTACTABILITY_DOCUMENT_UPLOAD } from '@fingo/lib/graphql';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { useBooleanState, useGetUser } from '@fingo/lib/hooks';
import { Button } from '@mui/material';
import React, { useState } from 'react';

const UploadContactabilityRequest = () => {
  const user = useGetUser();
  const [open, toggleOpen] = useBooleanState();
  const [files, setFiles] = useState([]);
  const [uploadContactability, { loading }] = useMutation(UPLOAD_CONTACTABILITY_DOCUMENT_UPLOAD, {
    variables: {
      upload: files[0],
    },
    onCompleted: () => {
      setFiles([]);
      toggleOpen();
    },
  });
  if (!userHasRoutePermission(user, 'users.add_contactabilitydocumentrequest')) return null;
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={toggleOpen}
      >
        Cargar fichas de contactabilidad
      </Button>
      <UploadDropZoneDialog
        open={open}
        setOpen={toggleOpen}
        files={files}
        setFiles={setFiles}
        message="Sube tu xlsx con una columna 'master_entity_id'"
        onCompleted={uploadContactability}
        loading={loading}
        buttonMessage="Cargar fichas"
      />
    </>
  );
};

export default UploadContactabilityRequest;
