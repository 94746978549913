import { useMutation } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import AsyncSelect from '@fingo/lib/components/selects/AsyncSelect';
import { useSnackBars } from '@fingo/lib/hooks';
import useGetExecutives from '@fingo/lib/hooks/useGetExecutives';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Casino from '@mui/icons-material/Casino';
import sample from 'lodash/sample';
import { DATA_GRID_COLUMNS } from '../../../constants/external-leads';
import { ASSIGN_EXTERNAL_LEADS, GET_EXTERNAL_LEADS } from '../../../graphql/external-leads';

const AssignExternalLeadsDialog = (props) => {
  const {
    open,
    setOpen,
    selectedExternalLeads,
    setSelectedExternalLeads,
  } = props;
  const [selectedExecutive, setSelectedExecutive] = useState(null);
  const { executives, loading } = useGetExecutives();
  const { addAlert } = useSnackBars();
  const [assignExternalLeads, { loading: assignExternalLeadsLoading }] = useMutation(
    ASSIGN_EXTERNAL_LEADS,
    {
      variables: {
        executiveId: selectedExecutive?.value,
        externalLeadIds: selectedExternalLeads.map((el) => el.id),
      },
      onCompleted: ({ assignExternalLeads: { assignedLeads } }) => {
        setSelectedExternalLeads([]);
        setOpen(false);
        const { executiveAssigned } = assignedLeads[0].masterEntity.company;
        const executiveFullName = `${executiveAssigned.firstName} ${executiveAssigned.lastName}`;
        addAlert({
          id: 'assigned-leads',
          message: `Se asignaron ${assignedLeads.length} leads a ${executiveFullName}`,
        });
      },
      refetchQueries: [GET_EXTERNAL_LEADS],
    },
  );

  const handleDelete = ({ id }) => {
    if (selectedExternalLeads?.length === 1) {
      setSelectedExternalLeads([]);
      setOpen(false);
    }
    setSelectedExternalLeads(
      selectedExternalLeads.filter((leadId) => leadId !== id),
    );
  };

  const options = executives?.map((executive) => ({
    label: `${executive.user.firstName} ${executive.user.lastName}`,
    value: executive.user.id,
  }));

  const columns = [
    ...DATA_GRID_COLUMNS,
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete External Lead"
          onClick={() => handleDelete(params.row)}
          key="deleteLead"
        />,
      ],
    },
  ];

  const dialogButtonDisabled = !selectedExecutive
    || !selectedExternalLeads?.length;

  const setRandomExecutive = () => {
    const executiveChoice = sample(executives);
    setSelectedExecutive(
      { label: executiveChoice?.user.completeName, value: executiveChoice?.user.id },
    );
  };
  return (
    <FingoDialog
      open={open}
      handleClose={() => setOpen(false)}
      title="Asignar leads externos a un ejecutivo"
      dialogActionButton={(
        <LoadingButton
          color="primary"
          onClick={assignExternalLeads}
          disabled={dialogButtonDisabled}
          loading={assignExternalLeadsLoading}
        >
          Asignar leads
        </LoadingButton>
      )}
      maxWidth="md"
      fullWidth
    >
      <Stack
        direction="column"
        justifyContent="space-around"
        alignItems="flex-end"
        width="100%"
        spacing={4}
        mt={2}
      >
        <Stack direction="row" spacing={1} alignItems="end">
          <Tooltip title="Aleatorio">
            <IconButton onClick={setRandomExecutive}>
              <Casino />
            </IconButton>
          </Tooltip>
          <AsyncSelect
            id="executive"
            options={options}
            loading={loading}
            value={selectedExecutive}
            setValue={setSelectedExecutive}
            label="Selecciona un ejecutivo"
          />
        </Stack>
        <FingoDataGrid
          rows={selectedExternalLeads}
          columns={columns}
          outsideBoxProps={{ width: '100%' }}
          density="compact"
        />
      </Stack>
    </FingoDialog>
  );
};

AssignExternalLeadsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedExternalLeads: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  setSelectedExternalLeads: PropTypes.func.isRequired,
};

export default AssignExternalLeadsDialog;
