import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useSnackBars } from '@fingo/lib/hooks';
import FileInput from '@fingo/lib/components/inputs/FileInput';
import { Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DISPLAY_INVOICE_RECOMMENDATIONS } from '../../graphql/company-recommendations/mutations';
import { RECOMMENDED_MASTER_ENTITIES } from '../../graphql/company-recommendations/queries';

const INITIAL_VALUES = { file: null };

const ALLOWED_EXTENSION = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

const VALIDATION_SCHEMA = Yup.object().shape({
  file: Yup.mixed()
    .required('Se requiere un archivo')
    .test(
      'fileFormat',
      'Solo se permiten archivos .xlsx o .csv',
      (value) => value && ALLOWED_EXTENSION.includes(value.type),
    ),
});

const DisplayInvoiceRecommendationsDialog = ({ open, onClose }) => {
  const { addAlert } = useSnackBars();

  const [displayInvoiceRecommendations, { loading }] = useMutation(
    DISPLAY_INVOICE_RECOMMENDATIONS,
    {
      refetchQueries: [RECOMMENDED_MASTER_ENTITIES],
      onCompleted: (data) => {
        const { invoiceRecommendations } = data.displayInvoiceRecommendations;
        addAlert({
          id: 'display-invoice-recommendations-success',
          message: `Se visibilizaron ${invoiceRecommendations.totalCount} recomendaciones`,
          severity: 'success',
          color: 'success',
        });
      },
      onError: ({ message }) => {
        addAlert({
          id: 'display-invoice-recommendations-success',
          message,
          severity: 'error',
          color: 'error',
        });
      },
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={onClose}
      title="Visibilizar recomendaciones"
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          displayInvoiceRecommendations({
            variables: {
              invoiceRecommendationsFile: values.file,
            },
            onCompleted: () => {
              setSubmitting(false);
              onClose();
            },
            onError: () => {
              setSubmitting(false);
            },
          });
        }}
      >
        {({ setFieldValue, isSubmitting, isValid }) => (
          <Form id="display-invoice-recommendations-form">
            <Stack direction="column" spacing={3}>
              <Typography variant="h2">
                Sube un archivo <strong>.xlsx</strong> o <strong>.csv</strong>{' '}
                con los campos:
              </Typography>
              <Typography fontFamily="Monospace" alignSelf="center">
                - invoice_id:{' '}
                <Typography variant="body2" component="span">
                  ID de la factura
                </Typography>
              </Typography>
              <Field name="file">
                {({ field }) => (
                  <FileInput
                    accept=".xlsx,.csv"
                    value={field.value}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue('file', file);
                    }}
                  />
                )}
              </Field>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={loading || isSubmitting}
                disabled={!isValid}
                sx={{
                  width: 150,
                  height: 40,
                  fontSize: 13,
                  alignSelf: 'center',
                }}
              >
                Confirmar
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </FingoDialog>
  );
};

DisplayInvoiceRecommendationsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DisplayInvoiceRecommendationsDialog;
