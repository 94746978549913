import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Chip } from '@mui/material';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { selectedInvoiceRecommendationIdsVar } from '../../../graphql/reactive-variables';
import DeclineInvoiceRecommendationsDialog from '../../dialogs/DeclineInvoiceRecommendationsDialog';

const DeclineInvoiceRecommendationsAction = () => {
  const [
    openDeclineInvoiceRecommendationsDialog,
    setOpenDeclineInvoiceRecommendationsDialog,
  ] = useState(false);
  const selectedInvoiceRecommendationIds = useReactiveVar(
    selectedInvoiceRecommendationIdsVar,
  );

  return (
    <>
      <Chip
        id="decline-invoice-recommendation-chip"
        icon={<ThumbDownAltIcon />}
        label="Gestión fallida"
        disabled={selectedInvoiceRecommendationIds.length === 0}
        color="error"
        onClick={() => setOpenDeclineInvoiceRecommendationsDialog(true)}
      />
      {openDeclineInvoiceRecommendationsDialog && (
        <DeclineInvoiceRecommendationsDialog
          open={openDeclineInvoiceRecommendationsDialog}
          handleClose={() => setOpenDeclineInvoiceRecommendationsDialog(false)}
        />
      )}
    </>
  );
};

export default DeclineInvoiceRecommendationsAction;
