import React, { useState } from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { MailParamType, MailTemplateType } from '@fingo/lib/propTypes';
import { MailTemplateForm, UploadControlGroupForm } from '@fingo/lib/components/forms';
import MailTemplatePreview from './MailTemplatePreview';

const MailTemplate = ({
  mailParams,
  selectedMailParam,
  setSelectedMailParam,
  companyId,
  mailVersionsForParam,
  setSelectedMail,
}) => {
  const [editMode, setEditMode] = useState(!!selectedMailParam?.template.id);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [blackListTemplate, setBlackListTemplate] = useState(selectedMailParam?.template?.version === 'BLACKLIST');
  const isBlackListInVersions = !!mailVersionsForParam.find((template) => template.version === 'BLACKLIST');
  const createBlackList = !isBlackListInVersions && blackListTemplate;

  return (
    <Stack spacing={3}>
      {showUploadFile ? (
        <UploadControlGroupForm
          mailParameterId={selectedMailParam?.id}
          companyId={companyId}
          createBlackList={createBlackList}
          setShowUploadFile={setShowUploadFile}
        />
      ) : (
        <MailTemplateForm
          mailParams={mailParams}
          selectedMailParam={selectedMailParam}
          setSelectedMailParam={setSelectedMailParam}
          companyId={companyId}
          mailVersionsForParam={mailVersionsForParam}
          editMode={editMode}
          setEditMode={setEditMode}
          showUploadFile={showUploadFile}
          setShowUploadFile={setShowUploadFile}
          blackListTemplate={blackListTemplate}
          setBlackListTemplate={setBlackListTemplate}
          setSelectedMail={setSelectedMail}
        />
      )}
      {editMode && !showUploadFile && selectedMailParam.template.template && !blackListTemplate && (
        <MailTemplatePreview template={selectedMailParam?.template?.template} />
      )}
    </Stack>
  );
};

MailTemplate.propTypes = {
  mailParams: PropTypes.arrayOf(MailParamType).isRequired,
  selectedMailParam: MailParamType,
  setSelectedMailParam: PropTypes.func.isRequired,
  companyId: PropTypes.string,
  mailVersionsForParam: PropTypes.arrayOf(MailTemplateType),
  setSelectedMail: PropTypes.func.isRequired,
};

MailTemplate.defaultProps = {
  selectedMailParam: null,
  companyId: null,
  mailVersionsForParam: [],
};

export default MailTemplate;
